import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router,{extractedRoutes} from "./router";
import axios from "axios";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// import BaiduMap from 'vue-baidu-map';
// import htmlToPdf from "@/utils/html2Pdf";
import "@/directive/vdonw";
import VueI18n from "vue-i18n";
import i18n from './lang';


Vue.config.productionTip = false;
Vue.mixin({
  mounted () {
    //搜索类型的el-select去掉readOnly属性移动端唤起键盘
    if (typeof this.$el.className === 'string') {
      if (this.$el.className.split(' ').indexOf('el-select') !== -1) {
        if (!this.$el.children[0] || !this.$el.children[0].children[0]) {
          return false
        }
        if(this.$el.children[0].children[0].getAttribute('placeholder')=='请选择 '||this.$el.children[0].children[0].getAttribute('placeholder')=='请输入关键词'||this.$el.children[0].children[0].getAttribute('placeholder')=='推送人'||this.$el.children[0].children[0].getAttribute('placeholder')=='接收人'||this.$el.children[0].children[0].getAttribute('placeholder')=='请输入用户名'){
            this.$el.children[0].children[0].removeAttribute('readOnly')
            this.$el.children[0].children[0].onblur = function () {
              let _this = this
              setTimeout(() => {
                _this.removeAttribute('readOnly')
              }, 200)
            }
        }
    
      }
    }
  }
})
Vue.use(VueI18n);
Vue.use(ElementUI);
// Vue.use(htmlToPdf);
// 引入重置样式
Vue.prototype.$ELEMENT = 3000;
// ElementUI.Dialog.props.closeOnClickModal.default = false; //取消dialog弹框，点击遮罩层关闭
Vue.use(ElementUI);
import Print from "vue-print-nb";
Vue.use(Print);
//定义全局变量
Vue.prototype.$imgSrc = process.env.VUE_APP_BASE_API=='http://m.sino-composite.com:8090/prod-test/'||process.env.VUE_APP_BASE_API=='http://m.sino-composite.com/prod-test/'?'/api/':process.env.VUE_APP_BASE_API;

let empArea=localStorage.getItem('empArea');
let urlPath="";
if(empArea=='青岛'||empArea=='QD'){
  urlPath=process.env.VUE_APP_BASE_QD;

}else if(empArea=='连云港'||empArea=='JS'){
  urlPath=process.env.VUE_APP_BASE_JS;
}else if(empArea=='科乐'||empArea=='CZ'){
  urlPath=process.env.VUE_APP_BASE_CZ;
}

import moment from 'moment'; // 时间处理
Vue.prototype.$moment = moment;
Vue.prototype.$urlPath = urlPath;
// Vue.use(BaiduMap, {
//   ak: 'fhwYGG2gYNxEYoDqNLQjvCbk8y5cchVQ'  //这个地方是官方提供的ak密钥
// })
import "./style/reset.scss";
import "./style/media.scss";
import "./assets/icon/iconfont.css";
const whiteList=['/emp_login','/404']
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (!extractedRoutes.includes(to.path)) {
      console.log('路由不存在')
      next('/')
  }
  let emp_userInfo = JSON.parse(localStorage.getItem("empUserInfo"));
  let sc_userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let bg_token=localStorage.getItem("bg_token");
  let empArea=localStorage.getItem('empArea');
  if(to.query.area_id){
    empArea=to.query.area_id;
    localStorage.setItem('empArea',empArea)
  }

  // console.log(token,'111');
  // return
  if(to.meta.is_emp==1){
    //设置企业微信带来的token，优先级高且一次性
    //获取企微跳转的token
    let head_token=to.query.token;
    let getHeadToken=localStorage.getItem("head_token");
    // return
    // axios.defaults.baseURL ='';

    if(head_token){
      localStorage.setItem("head_token",head_token);
        axios.defaults.headers.common['Token'] = head_token;
      // if(getHeadToken!=head_token){
      //   localStorage.setItem("head_token",head_token);
      //   axios.defaults.headers.common['Token'] = head_token;
      // }else{
      //   if(bg_token){
      //     axios.defaults.headers.common['Token']=bg_token;
      //   }else{
      //     axios.defaults.headers.common['Token']='';
      //   }
      // }
     
       
    }else if(emp_userInfo==null||emp_userInfo==undefined){
      if(bg_token){
        axios.defaults.headers.common['Token']=bg_token;
      }else{
        axios.defaults.headers.common['Token']='';
      }
    }else{
      axios.defaults.headers.common['Token'] = bg_token;
    }
   
     if(empArea=='青岛'||empArea=='QD'){
       // 环境的切换
      axios.defaults.baseURL =
        process.env.NODE_ENV === "development" ? '' : process.env.VUE_APP_BASE_QD;
        axios.defaults.headers.common['headarea']='qd';
      }else if(empArea=='连云港'||empArea=='JS'){
        axios.defaults.headers.common['headarea']='js';
        axios.defaults.baseURL =
          process.env.NODE_ENV === "development" ? '' : process.env.VUE_APP_BASE_JS;
      }else if(empArea=='科乐'||empArea=='CZ'){
        axios.defaults.headers.common['headarea']='cz';
        axios.defaults.baseURL =
        process.env.NODE_ENV === "development" ? '' : process.env.VUE_APP_BASE_CZ;
      }else{
        //确定不了代理时跳到登录页面
        if(whiteList.includes(to.path)){
          next()
        }else{
         next({path:'/emp_login'})
        }
      }
    
      

  }else{
    
    if(sc_userInfo){
    axios.defaults.headers.common['Token']=sc_userInfo.token;

    }else{
      axios.defaults.headers.common['Token']='';

    }
    axios.defaults.headers.common['headarea']='';
   
      
      axios.defaults.baseURL =
      process.env.NODE_ENV === "development" ? '' : process.env.VUE_APP_BASE_API;
    
    
  }
  // let area_id=to.query.area_id;
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
let flag;

async function startWatch() {
  flag = await getFlag();
  window.versionMonitor && clearInterval(window.versionMonitor);
  // 开启轮询执行judge函数
  window.versionMonitor = setInterval(() => {
    verify();
  }, 10*6000);
}
startWatch();
async function getFlag() {
  const response = await fetch(
    `${window.location.protocol}//${window.location.host}`,
    {
      method: "HEAD",
      cache: "no-cache"
    }
  );
  return response.headers.get("etag") || response.headers.get("last-modified");
}

async function verify() {
  const currentFlag = await getFlag();

  if (flag !== currentFlag) {
    // 执行更新逻辑
    window.location.reload();
    // console.log("更新了");
    // clearInterval(window.versionMonitor);
  }
}
Vue.prototype.axios = axios;

// const VUE_APP_VERSION = require('../package.json').version;
// const vers = window.localStorage.getItem('appVersion')
// if(VUE_APP_VERSION != vers){
//   localStorage.clear()
//   window.localStorage.setItem('appVersion', VUE_APP_VERSION)
//   window.location.reload();
// }
// // 注册全局钩子用来拦截导航
// router.beforeEach((to, from, next) => {
//   let token = window.localStorage.getItem('token');
//   if(to.meta.requiresAuth){
//     if (token) {
//         next();
//     } else {
//         localStorage.removeItem("token");
//         next({
//             path: '/login',
//             query: { redirect: to.fullPath } //跳到指定位置
//         })
//     }
//   }else{
//     next();
//   }
// });
// document.body.addEventListener('touchmove', function (e) {
//   e.preventDefault() // 阻止默认的处理方式(阻止下拉滑动的效果)
//   }, {passive: false})
new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount("#app");
