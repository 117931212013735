<template>
  <div class="home-box">
    <div class="header">
        <div class="iconfont">
            <img src="@/assets/image/logo.png" :width="pager==7?'225px':'172px'"  />
            <i class="el-icon-s-fold" :style="{ display: isCollapse == false ? '' : 'none',color:'#606266' }" @click="open"></i>
            <i class="el-icon-s-unfold" :style="{ display: isCollapse == true ? '' : 'none',color:'#606266' }" @click="open"></i>
        </div>
        <div class="user-box">
            <span style="display:inline-block;margin-right:10px">{{ area_name }}系统</span>
            <i v-if="pager==7" class="el-icon-user" style="display: inline-block; margin-right: 8px;color:#606266"></i>
            <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                <span v-if="pager==7">欢迎，</span>{{area_id+'_'}}{{userName}}<i class="el-icon-arrow-down el-icon--right" style="color:#606266"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                    <!-- <el-dropdown-item command="user">修改密码</el-dropdown-item> -->
                    <el-dropdown-item command="out">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
    <div class="main-box">
        <div class="menu" style="overflow:hidden">
            <el-menu
                class="el-menu-vertical-demo"
                @select="handleOpen"
                :collapse="isCollapse"
                :default-active="routerPath"
                :unique-opened="true">
                <template>
                </template>
                <template v-for="(item, index) in menuList">
                <el-menu-item :index="item.path||item.title" v-if="!item.children.length">
                    <i class="icon iconfont icon-jifen"></i>
                    <span slot="title">{{ item.menu_name }}</span>
                </el-menu-item>
                <el-submenu :index="item.id + ''" v-if="item.children.length">
                    <template slot="title">
                    <i :class="'icon iconfont '+item.icon"></i>
                    <span slot="title">{{ item.menu_name }}</span>
                    </template>
                    <el-menu-item-group>
                    <el-menu-item
                        v-for="i in item.children"
                        :index="i.path||i.title"
                        :key="i.pId"
                        @click="goPath(i.path)"
                    >
                        {{ i.menu_name }}
                    </el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
                </template>
            </el-menu>
        </div>
        <div class="main" v-if="pager == 7" :style="{ marginLeft: isCollapse ? '' : '240px', }"><router-view></router-view></div>
        <div class="main" v-else :style="{ marginLeft: isCollapse ? '' : '', }"><router-view></router-view></div>
        <div class="drawer-bg" @click="isCollapse = true" :class="pager == 5 && isCollapse == false ? 'drawer-none' : 'drawer-dis'"></div>
    </div>
  </div>
</template>
<script>
import { empUserInfo, empLoginOut, empMenu } from "@/api/api";
export default {
  data() {
    return {
        userName:'userName',
        area:'',
        isCollapse:'',
        pager:'',
        menuList:[],
        area_id:'',
        area_name:''
    }
  },
  computed:{
    routerPath() {
        let { path } = this.$route
        return path
    }
  },
  created(){
    empUserInfo().then(res=>{
        if (res.status == 2000) {
            localStorage.setItem("empUserInfo", JSON.stringify(res.data));
            localStorage.setItem("bg_token",JSON.stringify(res.data.token));
          
           
            this.userName = res.data.user_name;
            this.area_id = res.data.area_id;
            if(this.area_id=='QD'){
              this.area_name='青岛'
            }else if(this.area_id=='CZ'){
              this.area_name='科乐'
            }else{
              this.area_name='连云港'
            }
            //导航菜单
            empMenu().then((res) => {
            // localStorage.setItem("menuList", JSON.stringify(res.data));
            this.menuList = res.data;
            });
        } else {
            // this.$router.push("/emp_login");
        }
    })
  },
  mounted(){
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
    if (document.body.clientWidth <= 500) {
      this.pager = 5;
      this.isCollapse = true;
    } else {
      this.pager = 7;
      this.isCollapse = false;
    }
  },
  methods:{
    open() {
      this.isCollapse = !this.isCollapse;
    },
    //跳转路由
    goPath(pathName) {
      this.$router.push({
        path: pathName,
      });
      if(document.body.clientWidth<=500){
        this.isCollapse=true;
      }
      
    },
    handleCommand(e) {
        if(e=='out'){
            empLoginOut().then(res=>{
                if(res.status==2000){
                    this.$message({type:"success",message:'退出成功'})
                    localStorage.removeItem("empUserInfo");
                    localStorage.removeItem("bg_token");
                    localStorage.removeItem("head_token");
                    localStorage.removeItem("empArea");


                    this.$router.push("/emp_login");
                }else{
                    // this.$message({type:"error",message:res.msg})
                }
            })
        }
    },
    handleOpen(key, keyPath) {
      if (this.$route.path.includes("/emp/index/index")) {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    },
  }
}
</script>
<style scoped lang="scss">
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.el-dropdown{
    cursor: pointer;
  }
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
// 选中颜色
.el-menu-item.is-active {
  background: #2d8cf0 !important;
    color: #fff !important;
}
.el-submenu.is-active{
  // background: #000 !important;
  .el-submenu__title{
    span{
      color: #fff !important;
    }
    i{
      color: #fff;
    }
  }
  
}
.el-icon-s-fold,.el-icon-s-unfold{
    color: #000;
}
.user-box {
  cursor: pointer;
}
.header {
    position: fixed;
    width: 100%;
    height: 66px;
    background-image: linear-gradient(to bottom,#fff 0,#f8f8f8 100%);
    background-repeat: repeat-x;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 5px rgba(0,0,0,.075);
    background-color: #f8f8f8;
    border-color: #e7e7e7;
    display: flex;
    align-items: center;
    padding: 0 18px 0 4px;
    box-sizing: border-box;
    justify-content: space-between;
    transition: all 1s ease-in-out;
    z-index: 2000;
}

.iconfont {
  display: flex;
  i {
    display: inline-block;
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    margin-left: 26px;
    line-height: 48px;
  }
}
.menu {
//   width:240px;
  height: 100vh;
//   background: #001529;
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
  // margin-top:4px;
  // background: rgb(160,207,255);
  position: fixed;
  z-index: 13 !important;
}
.main-box {
  width: 100% !important;
  box-sizing: border-box;
  position: absolute;
  top: 66px;
  .main {
    transition: all .3s ease-in-out;
    // width:100%;
    // position: absolute;
    // z-index: 10 !important;
  }
}
.drawer-bg {
  background: #000;
  opacity: .3;
  width: 100%;
  top: 0;
   height: calc(100vh - 66px);
  position: absolute;
  z-index: 8 !important;
  transition: all 1s ease-in-out;
}
.drawer-dis {
  display: block;
}
.drawer-dis {
  display: none;
}
.el-menu {
//   background: rgba(0, 21, 41);
//   padding-top: 6px;
//   box-sizing: border-box;
//   border: none;
  .el-submenu__title span {
    color: rgba(165, 172, 179);
  }
  .el-menu-item {
    color: rgba(165, 172, 179);
  }

  .el-menu-item,
  .el-menu-item-group {
    background: rgba(15, 3, 3);
  }
  ::v-deep .el-submenu__title:hover {
    background-color: #000 !important;
  }
  .el-submenu__title:hover {
    span,
    i {
      color: #fff;
    }
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 240px;
  height: calc(100vh - 64px);
  overflow: scroll;
  background: #001529;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .32), 0 0 6px rgba(0, 0, 0, .04);
//   border-radius: 2%;
  color: #fff;
}
.el-menu--collapse {
  width: 0px !important; //关闭菜单 不显示图标
  height: calc(100vh - 64px);
  // background: rgb(217,236,255);
  // background:rgb(57,63,95);
  // background:rgb(0,53,103);
  box-shadow: 0 2px 4px rgba(0, 0, 0, .32), 0 0 6px rgba(0, 0, 0, .04);
  border-radius: 2%;
  color: #fff;
}
</style>