<template>
  <div id="app">
    <keep-alive >
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: 'App',
  data(){
    return{
      include: [],
    }
  },
  watch: {
      $route(to, from) {
        if(to.name!='工厂请假审批'){
          // let url=process.env.VUE_APP_BASE_API=='http://m.sino-composite.com:8090/prod-test/'?'/api/':'/';
          // axios.defaults.baseURL=url;
        }
          // 如果 要 to(进入) 的页面是需要 keepAlive 缓存的，把 name push 进 include数组
          if (to.meta.keepAlive) {
              if (!this.include.includes(to.name)) {
                  this.include.push(to.name);
              }else{
                this.includes=[];
              }
          }
      }
  },
  created(){
    let area_id=this.$route.query.area_id;
    if(area_id=='QD'){
      localStorage.setItem('empArea','青岛');
    }else if(area_id=='JS'){
      localStorage.setItem('empArea','连云港');
    } 
    },
  methods:{
    getVersion() {
      let url = `//${window.location.host}/version.json`;
      console.log(process.env.NODE_ENV);
      //production
      if (process.env.NODE_ENV === "production") {
        axios.get(url).then(res => {
          let lastVersion = res.data.version;
          console.log(res.data,localStorage.version );
          if (localStorage.version == undefined) {
            localStorage.setItem("version", lastVersion);
            window.location.reload();
          } else {
            if (localStorage.version != lastVersion) {
              localStorage.removeItem("version");
              
                  window.location.reload();

            }
          }
        })
      } 
    }

  }
}
</script>

<style lang="scss">

#app {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: #f4f4f5;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
 
}
</style>
