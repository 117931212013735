<template>
  <div class="home-box">
    <div
          class="mask"
          @click="isCollapse = true"
          :style="{ display: isCollapse == false ? '' : 'none' }"
        ></div>
    <!-- 左侧菜单 -->
    
    <div class="home-left" :style="{zIndex: isCollapse ? '2':'7',left: isLeft&&isCollapse ?'-64px':'0px',width:isCollapse?'64px':'280px'}">
        <!-- <div
          class="mask"
          @click="isCollapse = true"
          :style="{ display: isCollapse == false ? '' : 'none' }"
        ></div> -->
        <div class="home-login-box" :style="{width:isLeft?'':'280px'}">
          <img src="@/assets/image/loginIco.png" alt="" :style="{width: isCollapse ? '26px':'56px',height:isCollapse ? '26px':'38px'}" />
          <h2 :style="{display:isCollapse?'none':''}">科拉斯</h2>
        </div>  
      <el-menu
        class="el-menu-vertical-demo"
        @select="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        :default-active="routerPath"
        :unique-opened="true"
      >
        <template>
          
        </template>
        <template v-for="(item, index) in menuList">
          <el-menu-item :index="item.path||item.title" v-if="!item.children.length">
            <i class="icon iconfont icon-jifen"></i>
            <span slot="title">{{ item.menu_name }}</span>
          </el-menu-item>

          <el-submenu :index="item.id + ''" v-if="item.children.length">
            <template slot="title">
              <i :class="'icon iconfont '+item.icon"></i>
              <span slot="title">{{ item.menu_name }}</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-for="i in item.children"
                :index="i.path||i.title"
                :key="i.pId"
                @click="goPath(i.path)"

              >
                {{ i.menu_name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </template>
      </el-menu>
    </div>

    <!-- 右侧主体部分 -->
    <div class="home-right-box" style="width: 100%">
      <div
        class="home-right"
        :style="{
          width: isCollapse ? 'calc(100% - 64px)' : 'calc(100% - 280px)',
          marginLeft: isLeft&&isCollapse?'0px':''
        }"
      >
        <div class="header">
          <div @click="changeBtn">
            <i
              class="el-icon-s-fold"
              :style="{ display: isCollapse == false ? '' : 'none' }"
            ></i>
            <i
              class="el-icon-s-unfold"
              :style="{ display: isCollapse == true ? '' : 'none' }"
            ></i>
          </div>
        </div>
        <div class="header-right">
          <p @click="goSc">返回主页</p>
          <i
            class="el-icon-user"
            style="display: inline-block; margin-right: 8px"
          ></i>
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              欢迎，{{ userName
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="user">个人中心</el-dropdown-item>
              <el-dropdown-item command="out">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

      <div
        class="mainBox"
        :style="{ marginLeft: isCollapse ? '65px' : '280px' }"
      >
        <!-- <el-main>首页</el-main> -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import { userInfo, menu, loginOut } from "@/api/api";
import axios from "axios";
// import menuLeft from '@/components/Layout/menuLeft.vue';
export default {
  data() {
    return {
      userName: "",
      isCollapse: '',
      leftVal:'',
      isLeft:'',
      menuList: [],
      isHome: true,
      path:''
    };
  },
  computed:{
     routerPath() {
        let { path } = this.$route

        // 个人中心折叠菜单
        // if(path === '/user/user') {
        //   this.defaultOpenedsArray = []
        // }

        return path
      }
  },
  mounted() {
    
   if (this.$route.path.includes("/home/index/index")) {
      this.isHome = true;
    } else {
      this.isHome = false;
    }
    const that = this;
    window.onresize = () => {
      return (() => {
        that.isCollapse = document.body.clientWidth <= 500 ? true : false;
      })();
    };
    if(document.body.clientWidth <= 500){
      this.isCollapse=true;
      this.isLeft=true;
      this.leftVal='-64px'
    }else{
      if (this.$route.path.includes("/home/sc_user/user_edit")) {
        this.isCollapse=true;
        // this.isLeft=true;
        // this.leftVal='-64px'
      }else{
        this.isCollapse=false;
        this.isLeft=false;
        this.leftVal='0px'
      }
      

    }
  },
  methods: {
    changeBtn() {
      this.isCollapse = !this.isCollapse;
    },
    //跳转路由
    goPath(pathName) {
      this.$router.push({
        path: pathName,
      });
      if(document.body.clientWidth<=500){
        this.isCollapse=true;
      }
      
    },
    //
    goSc(){
      window.location.href='http://m.sino-composite.com/';
    },
    //触发用户名
    handleCommand(e) {
      //退出登录
      if (e == "out") {
        loginOut().then((res) => {
          if (res.status == 2000) {
            this.$message({
              message: "退出成功",
              type: "success",
            });
            localStorage.removeItem("userInfo");
            localStorage.removeItem("token");
            localStorage.removeItem("menuList");
            localStorage.removeItem("priceOrder");
            localStorage.removeItem("queryUser");
            localStorage.removeItem('carMessagePlate');
            localStorage.removeItem('carMessageCode');
            localStorage.removeItem('carKeepPlate');
            localStorage.removeItem('carKeepCode');
            localStorage.removeItem('examCode');

            // localStorage.removeItem("empUserInfo");
            // localStorage.removeItem("bg_token");
            // localStorage.removeItem("empArea");
            // localStorage.removeItem("head_token");



            this.$router.push("/login");
          }
        });
      } else {
        //跳转个人中心
      }
    },
    handleOpen(key, keyPath) {
      if (this.$route.path.includes("/home/index/index")) {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    },
    handleClose(key, keyPath) {
    },
  },
  created() {
    //获取用户信息
  
    userInfo().then((res) => {
      if (res.status == 2000) {
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        // let token=localStorage.getItem('token');
        localStorage.setItem("token",JSON.stringify(res.data.token));
        this.userName = res.data.user_name;
        //导航菜单
        menu().then((res) => {
          localStorage.setItem("menuList", JSON.stringify(res.data));
          this.menuList = res.data;
        });
      } else {
        // this.$message({
        //   message: res.msg,
        //   type: "warning",
        // });
        this.$router.push("/login");
      }
    });
    if (this.$route.path.includes("/home/index/index")) {
      this.isHome = true;
    } else {
      this.isHome = false;
    }
  },
};
</script>
<style scoped lang="scss">
  .el-dropdown{
    cursor: pointer;
  }
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
// 选中颜色
.el-menu-item.is-active {
  background: #2d8cf0 !important;
    color: #fff !important;
}
.el-submenu.is-active{
  // background: #000 !important;
  .el-submenu__title{
    span{
      color: #fff !important;
    }
    i{
      color: #fff;
    }
  }
  
}
// .el-menu .el-submenu__title span{
//   color: red !important;
// }
.home-box {
  display: flex;
}
.home-left {
  // background: red;
  height: 100vh;
  overflow: scroll;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  background: rgba(0, 21, 41);
  position: fixed;
  .home-login-box {
    // width: 280px;
    display: flex;
    padding: 12px 20px 0px;
    box-sizing: border-box;
    background: rgb(0, 21, 41);
    // line-height: 20px;
    transition: all 0.3s ease-in-out;
    h2{
      color: rgb(24, 144, 255);
      font-size: 26px;
      font-weight: bold;
      margin-left: 20px;
      margin-top: 8px;
      letter-spacing: 4px;
      
    }
    img{
      width: 56px;
      transition: all 0.3s ease-in-out;

    }
  }
}
.el-menu {
  // height: 94vh;
  background: rgba(0, 21, 41);
  padding-top: 6px;
  box-sizing: border-box;
  // background: #fff;

  border: none;
  .el-submenu__title span {
    // color: #fff;
    color: rgba(165, 172, 179);
  }
  .el-menu-item {
    color: rgba(165, 172, 179);
  }

  .el-menu-item,
  .el-menu-item-group {
    background: rgba(15, 3, 3);
  }
  ::v-deep .el-submenu__title:hover {
    background-color: #000 !important;
  }
  .el-submenu__title:hover {
    span,
    i {
      color: #fff;
    }
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 280px;
  min-height: 400px;

}
.home-right {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 280px);
  height: 52px;
  background: #fff;
  // box-shadow:  1px 4px rgb(0,21,41,0.8);
  box-shadow: 0 15px 10px -22px #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  z-index: 2000;
  .header {
    i {
      font-size: 20px;
    }
  }
  .header-right {
    display: flex;
    p {
      margin-right: 40px;
      cursor: pointer;
    }
  }
}
.mainBox {
  margin-top: 50px;
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width:500px) {
  .el-menu {
    z-index: 7;
  }
  .home-left {
    // width: 280px;
  background: rgba(0, 21, 41);
    position: absolute;
    z-index: 7;
    // display:none;
    .home-login-box{
      // width: 0px;
      z-index:7;
    }
    // z-index: 9999;
    // background-color: red;
  }
  // .el-menu-vertical-demo:not(.el-menu--collapse) {
  //   z-index:3;
  // }
  .mask {
    width: 100%;
    height: 100vh;
    background:#000;
    opacity: 0.6;
    z-index: 6;
    position: absolute;
  }
  .home-right-box{
    // z-index:5;
    background: #f4f4f5;
  }
  .home-right {
    // width: calc(100% - 15.5%) !important;
    width: 100% !important;
    // left: 15.5%;
    z-index: 5;
  }
 
  .mainBox {
    width: 100% !important;

    margin-left: 0px !important;
    // z-index: -1 !important;
  }
}
</style>
