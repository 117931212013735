const state={
    query:{
        user_id:'',
        name:'',
    }
}
const getters={}
const actions={}
const mutations ={
    setQuery(state,value){
        state.query.user_id=value.user_id;
        state.query.name=value.name;

    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
