import Vue from "vue";
import VueI18n from "vue-i18n";
import zn from './zn';
import en from './en';
import cn from './cn';

Vue.use(VueI18n);
const i18n= new VueI18n({
    locale:window.localStorage.getItem('lang')?window.localStorage.getItem('lang'):'zn',
    messages:{
        "zn":zn,
        'en':en,
        'cn':cn
    }
})
export default i18n;